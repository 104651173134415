import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import ReactTable, { ReactTableDefaults } from 'react-table';
import { push } from 'react-router-redux';
import {
  get,
  uniq,
  filter,
  omit,
  maxBy,
} from 'lodash';
import moment from 'moment';
import exportExcel from '../../helpers/exportExcel';
import getLabel from '../../helpers/getLabel';
import Loading from '../Loading';
import BlankState from '../BlankState';
import { PAGE_SIZE } from '../../config';
import Pagination from '../Pagination';
import { schoolsTeachersAidQuery } from '../../apollo';


class SchoolTableTeachersAid extends Component {
  state = {
    searchTerm: '',
  }

  exportSchools = () => {
    const tableData = this.selectTable.getResolvedState().sortedData;
    const filteredData = tableData.map(({ _original }) => _original);

    const columnData = [
      { header: 'School Name', key: 'name' },
      { header: 'District', key: 'districtName' },
      { header: 'Zip', key: 'zip' },
      { header: 'County', key: 'county' },
      { header: 'State', key: 'state' },
      { header: 'Address 1', key: 'address1' },
      { header: 'Address 2', key: 'address2' },
      { header: 'Level', key: 'type' },
      { header: 'Program Status', key: 'statusTeachersAid' },
      { header: 'Term', key: 'termFormattedShort' },
      { header: 'Date Submitted', key: 'submittedAtFormatted' },
      { header: 'Application Status', key: 'applicationStatus' },
      { header: 'Certified Verification', key: 'certififedVerification' },
      { header: 'Waitlisted', key: 'waitlisted' },
      { header: 'Waitlisted At', key: 'waitlistedAtFormatted' },
      { header: 'Free Lunch', key: 'numFreeLunch' },
      { header: 'Reduced Lunch', key: 'numReducedLunch' },
      { header: 'Total Enrollment', key: 'numTotalEnrollment' },
      { header: 'Participated in program last year', key: 'participatedLastYear' },
      { header: 'Principal Name', key: 'principalName' },
      { header: 'Principal Email', key: 'principalEmail' },
      { header: 'Site Coordinator Name', key: 'siteCoordinatorName' },
      { header: 'Site Coordinator Email', key: 'siteCoordinatorEmail' },
    ];

    const rowData = [];

    filteredData.forEach((school) => {
      const principal = school.teamMembersTeachersAid.find(({ type }) => type === 'PRINCIPAL');
      const siteCoordinator = school.teamMembersTeachersAid.find(({ type }) => type === 'SITE_COORDINATOR');

      const schoolData = {
        ...school,
        principalName: get(principal, 'fullName', 'N/A'),
        principalEmail: get(principal, 'email', 'N/A'),
        siteCoordinatorName: get(siteCoordinator, 'fullName', 'N/A'),
        siteCoordinatorEmail: get(siteCoordinator, 'email', 'N/A'),
      };

      if (school.applicationsTeachersAid.length) {
        school.applicationsTeachersAid.forEach((application) => {
          const applicationStatus = application.status;
          const participatedLastYear = application.new ? 'No' : 'Yes';
          const submittedAtFormatted = application.submittedAt
            ? moment.utc(application.submittedAt).format('M-D-YY')
            : '';
          const waitlisted = application.waitlisted ? 'Yes' : 'No';
          const waitlistedAtFormatted = application.waitlistedAt
            ? moment.utc(application.waitlistedAt).format('M-D-YY')
            : '';

          rowData.push({
            submittedAtFormatted,
            participatedLastYear,
            applicationStatus,
            waitlisted,
            waitlistedAtFormatted,
            ...schoolData,
            ...omit(application, ['status', 'waitlisted', 'waitlistedAt']),
          });
        });
      } else {
        rowData.push({
          ...schoolData,
        });
      }
    });

    exportExcel('schools', columnData, rowData);
  };


  selectFilter = (props, termSchools, options = null) => {
    const { filter: filterObj, onChange, column } = props;

    const uniqOptions = uniq(termSchools.map(school => (
      get(school, `[${column.id}]`)
    )));

    return (
      <select
        className="filter-select w-select"
        onChange={event => onChange(event.target.value)}
        value={filterObj ? filterObj.value : ''}
      >
        <option value="">{column.Header}</option>
        {
          uniqOptions.map(value => (
            <option key={`${value}+${column.id}`} value={value}>{(options && value !== 'N/A') ? getLabel(options, value) : value}</option>
          ))
        }
      </select>
    );
  }

  searchResults(termSchools) {
    const { searchTerm } = this.state;

    return filter(termSchools, ({
      name,
      districtName,
      zip,
      county,
      type,
      statusTeachersAid,
    }) => {
      const lowerCase = text => text.toLowerCase();
      const regexFunc = fieldValueSearch => new RegExp(searchTerm).test(fieldValueSearch);

      return regexFunc(lowerCase(name))
        || regexFunc(lowerCase(districtName))
        || regexFunc(lowerCase(type))
        || regexFunc(lowerCase(county))
        || regexFunc(zip)
        || regexFunc(lowerCase(statusTeachersAid));
    });
  }

  render() {
    const { selectedTermId, enums, dispatch } = this.props;

    const {
      schoolTypes,
      programStatuses,
    } = enums;

    return (
      <Query
        query={schoolsTeachersAidQuery}
        variables={{ termId: selectedTermId }}
        fetchPolicy="cache-and-network"
      >
        {({ loading, data, error }) => {
          if (error) return <BlankState title="Error" subtitle="There was an error loading" />;
          if (!data.schoolsTeachersAid && loading) return <Loading />;

          const { schoolsTeachersAid: termSchools } = data;
          const filteredTermSchools = this.searchResults(termSchools);

          const columns = [{
            Header: 'Teachers Aid #',
            accessor: 'teachersAidSchoolNumber',
            filterable: false,
          }, {
            Header: 'School',
            accessor: 'name',
            filterable: false,
          }, {
            Header: 'District',
            accessor: 'districtName',
          }, {
            Header: 'Zip',
            accessor: 'zip',
          }, {
            Header: 'County',
            accessor: 'county',
          }, {
            Header: 'Level',
            accessor: 'type',
            Cell: row => getLabel(schoolTypes.options, row.value),
            Filter: props => this.selectFilter(props, termSchools, schoolTypes.options),
          }, {
            id: 'numberReducedLunch',
            Header: 'Red. Lunch #',
            filterable: false,
            accessor: d => d.applicationsTeachersAid,
            Cell: (row) => {
              if (row.value.length) {
                const activeApplications = row.value.filter(({ status }) => status === 'APPROVED');
                const mostRecent = maxBy(activeApplications, o => o.createdAt);

                return get(mostRecent, 'numReducedLunch', 'N/A');
              }
              return 'N/A';
            },
          }, {
            id: 'numberFreeLunch',
            Header: 'Free Lunch #',
            filterable: false,
            accessor: d => d.applicationsTeachersAid,
            Cell: (row) => {
              if (row.value.length) {
                const activeApplications = row.value.filter(({ status }) => status === 'APPROVED');
                const mostRecent = maxBy(activeApplications, o => o.createdAt);

                return get(mostRecent, 'numFreeLunch', 'N/A');
              }
              return 'N/A';
            },
          }, {
            Header: 'Program Status',
            accessor: 'statusTeachersAid',
            Cell: row => getLabel(programStatuses.options, row.value),
            Filter: props => this.selectFilter(props, termSchools, programStatuses.options),
          }, {
            id: 'new',
            Header: 'Returning',
            accessor: (d) => {
              const activeApplications = d.applicationsTeachersAid.filter(({ status }) => status === 'APPROVED');
              const mostRecent = maxBy(activeApplications, o => o.createdAt);
              if (mostRecent) {
                return mostRecent.new ? 'No' : 'Yes';
              }
              return 'N/A';
            },
            Filter: (props) => {
              const { filter: filterObj, onChange, column } = props;
              return (
                <select
                  className="filter-select w-select"
                  onChange={event => onChange(event.target.value)}
                  value={filterObj ? filterObj.value : 'all'}
                >
                  <option value="">{column.Header}</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              );
            },
          }, {
            id: 'archived',
            Header: 'Archived',
            accessor: row => (row.deletedAt ? 'Yes' : 'No'),
            Filter: (props) => {
              const { onChange, column } = props;
              return (
                <select
                  className="filter-select w-select"
                  onChange={event => onChange(event.target.value)}
                >
                  <option value="No">{column.Header}</option>
                  <option value="">Show All</option>
                </select>
              );
            },
          }];

          return (
            <Fragment>
              <div className="date-export admin-schools backpack-buddy">
                <div
                  onClick={this.exportSchools}
                  className="export-btn w-button admin-schools"
                >
                  Export
                </div>
              </div>
              <input
                type="text"
                className="search-bar w-input"
                onChange={evt => (
                  this.setState({ searchTerm: evt.target.value.trim().toLowerCase() })
                )}
                placeholder="Search"
              />
              <ReactTable
                className="schools-table teachers-aid"
                ref={(r) => {
                  this.selectTable = r;
                }}
                data={filteredTermSchools}
                columns={columns}
                loadingText=""
                NoDataComponent={() => <BlankState title="No Results" />}
                minRows={0}
                filterable
                defaultPageSize={PAGE_SIZE}
                PaginationComponent={Pagination}
                defaultFiltered={[{
                  id: 'archived',
                  value: 'No',
                }]}
                defaultSorted={[{
                  id: 'name',
                  desc: false,
                }]}
                getTrGroupProps={(state, rowInfo) => ({
                  className: 'table-row school-row w-clearfix',
                  style: { cursor: 'pointer' },
                  onClick: () => dispatch(push(`/teachers-aid/schools/${rowInfo.original.schoolId}`)),
                })}
                getTdProps={() => ({
                  className: 'table-col schools-cells',
                })}
                getTheadTrProps={() => ({
                  className: 'table-row table-header w-clearfix',
                })}
                getTheadFilterTrProps={() => ({
                  className: 'filters',
                })}
                column={
                  {
                    ...ReactTableDefaults.column,
                    headerClassName: 'table-col schools-header w-inline-block pointer noselect',
                    minWidth: 85,
                    width: '10%',
                    Cell: row => row.value || 'N/A',
                    Filter: props => this.selectFilter(props, termSchools),
                  }
                }
              />
            </Fragment>
          );
        }
        }
      </Query>
    );
  }
}

SchoolTableTeachersAid.propTypes = {
  selectedTermId: PropTypes.string.isRequired,
  enums: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default SchoolTableTeachersAid;
