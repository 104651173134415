import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import AuthRoute from './AuthRoute';
import ApplicationBackpackBuddy from '../components/ApplicationBackpackBuddy';
import AdminUsersPage from '../pages/AdminSchoolProgramUsersPage';
import AdminSchoolsPage from '../pages/AdminSchoolsPage';
import AdminDistrictsPage from '../pages/AdminSchoolProgramDistrictsPage';
import AdminSchoolProgramSettingsPage from '../pages/AdminSchoolProgramSettingsPage';
import AdminDocumentsPage from '../pages/AdminDocumentsPage';
import AdminSurveysPage from '../pages/AdminSurveysPage';
import AdminResourcesPage from '../pages/AdminResourcesPage';
import SignaturePage from '../pages/SignaturePage';

const BackpackBuddyAdminRoutes = ({ match }) => (
  <Switch>
    <AuthRoute path={`${match.path}/schools`} component={AdminSchoolsPage} />
    <AuthRoute path={`${match.path}/districts`} component={AdminDistrictsPage} />
    <AuthRoute path={`${match.path}/users`} component={AdminUsersPage} />
    <AuthRoute path={`${match.path}/program-settings`} component={AdminSchoolProgramSettingsPage} />
    <AuthRoute exact path={`${match.path}/documents`} component={AdminDocumentsPage} />
    <AuthRoute exact path={`${match.path}/surveys`} component={AdminSurveysPage} />
    <AuthRoute exact path={`${match.path}/resources`} component={AdminResourcesPage} />
    <AuthRoute path={`${match.path}/application/:step/:id`} component={ApplicationBackpackBuddy} />
    <AuthRoute exact path={`${match.path}/principal-signature/:id`} component={SignaturePage} />
    <Route component={() => <Redirect to={`${match.path}/schools`} />} />
  </Switch>
);

BackpackBuddyAdminRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default BackpackBuddyAdminRoutes;
