import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import moment from 'moment';
import classnames from 'classnames';
import {
  programTermsQuery,
} from '../../apollo';
import BlankState from '../BlankState';
import Loading from '../Loading';
import { DEFAULT_TERM_YEAR } from '../../config';
import ApplicationsList from '../ApplicationsList';
import isSite from '../../helpers/isSite';

const ApplicationsTab = (props) => {
  const nextTermStartYear = moment.utc(DEFAULT_TERM_YEAR).add(1, 'year').format('YYYY');
  const nextTermEndYear = moment.utc(DEFAULT_TERM_YEAR).add(2, 'year').format('YYYY');
  const { program: { name, programUrl } } = props;

  return (
    <div className="table-wrapper applications">
      <h2>Applications</h2>
      <ApplicationsList {...props} />
      {
        isSite(name)
          ? (
            <div className="apply-options">
              <div className="w-col-6 apply-section">
                <h4>Apply for Another Site</h4>
                <p>
                    Are you affiliated with another site?
                    Complete another application to add another site to your account.
                </p>
                <Link
                  to={`${programUrl}/application/step-1`}
                  className="btn-green w-button apply-section-button"
                >
                    Apply
                </Link>
              </div>
            </div>
          )
          : (
            <Query
              query={programTermsQuery}
              variables={{
                termYear: nextTermStartYear,
                programName: name,
              }}
            >
              {({ loading, data, error }) => {
                if (loading) return <Loading />;
                if (error) return <BlankState title="Error" subtitle="There was an error loading" />;

                const termsAvailable = Boolean(data.programTerms.length);
                const summerTermAvailable = data.programTerms.find(({ type }) => type === 'SUMMER');
                const schoolTermAvailable = data.programTerms.find(({ type }) => type === 'SCHOOL');

                return (
                  <div className="apply-options">
                    <div className="w-col-6 apply-section">
                      <h4>Apply Again</h4>
                      <p>
                        {`Are you affiliated with another school or need to apply again?
                          Complete another application for Summer ${DEFAULT_TERM_YEAR} or School Year ${DEFAULT_TERM_YEAR}-${nextTermStartYear}.`}
                      </p>
                      <Link
                        to={`${programUrl}/application/step-1`}
                        className="btn-green w-button apply-section-button"
                      >
                          Apply
                      </Link>
                    </div>
                    <div className="div-block-16" />
                    <div className="w-col-6 apply-section">
                      <h4>Apply for Next Year</h4>
                      <p>
                        {
                          schoolTermAvailable
                            && (
                              <>
                                {`Complete your application and submit by May 30, ${nextTermStartYear}
                              to participate in School Year ${nextTermStartYear}-${nextTermEndYear}.`}
                              &nbsp;
                              </>
                            )
                        }
                        {
                          summerTermAvailable
                            && `Complete your application and submit by August 2, ${nextTermStartYear} to participate 
                              in Summer ${nextTermStartYear}.`

                        }
                        {
                          !termsAvailable && 'Applications for next year are not available. Please check back at a later time.'
                        }
                      </p>
                      <Link
                        to={{
                          pathname: `${programUrl}/application/step-1`,
                          search: `?termYear=${nextTermStartYear}`,
                        }}
                        className={classnames('btn-green w-button apply-section-button', { 'disabled-link-button': !termsAvailable })}
                      >
                          Apply
                      </Link>
                    </div>
                  </div>
                );
              }}
            </Query>
          )
      }
    </div>
  );
};

ApplicationsTab.propTypes = {
  program: PropTypes.object.isRequired,
};

export default ApplicationsTab;
