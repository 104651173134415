import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

const exportExcel = (fileName = 'backpack-buddy-export', columnData = [], rowData = []) => {
  const workbook = new Workbook();
  const sheet = workbook.addWorksheet('Backpack Buddy Export');

  sheet.columns = columnData;
  sheet.addRows(rowData);

  workbook.xlsx.writeBuffer().then((data) => {
    try {
      const file = new File([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(file, `${fileName}.xlsx`);
    } catch (err) {
      const textFileAsBlob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      window.navigator.msSaveBlob(textFileAsBlob, `${fileName}.xlsx`);
    }
  });
};

export default exportExcel;
