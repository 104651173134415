import gql from 'graphql-tag';

export default gql`
  query {
    userApplicationsKidsCafe {
      status
      applicationKidsCafeId
      applicationYear
      type
      site {
        name
      }
    }
  }
`;
