import gql from 'graphql-tag';

export default gql`
  query adminSite ($siteId: ID!) {
    adminSite(siteId: $siteId) {
      id
      siteId
      name
      address1
      address2
      crossStreet
      closestSchool
      city
      state
      zip
      county
      lastEdited
      lastEditedSiteMembers
      firstYearApplied
      statusKidsCafe
      statusKidsCafeUpdatedAt
      deletedAt
      applicationsKidsCafe {
        id
        applicationKidsCafeId
        new
        status
        applicationYear
        type
        createdAt
        submittedAt
        submittedBy
      }
      siteMembers {
        id
        siteMemberId
        firstName
        lastName
        email
        phone
        type
        title
        isSupervisor
      }
    }
  }
`;
