import React from 'react';
import ApplicationForm from './ApplicationForm';

const Applications = props => (
  <div className="table-wrapper-3 applications">
    <h2>Applications</h2>
    <ApplicationForm {...props} />
  </div>
);

export default Applications;
