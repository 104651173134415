import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import ReactTable, { ReactTableDefaults } from 'react-table';
import { push } from 'react-router-redux';
import { get, uniq, filter, omit, maxBy } from 'lodash';
import moment from 'moment';
import exportExcel from '../../helpers/exportExcel';
import getLabel from '../../helpers/getLabel';
import Loading from '../Loading';
import BlankState from '../BlankState';
import { PAGE_SIZE } from '../../config';
import Pagination from '../Pagination';
import { sitesQuery } from '../../apollo';
import ChevronDropIcon from '../../images/chevron-drop.svg';

class SiteTable extends Component {
  state = {
    searchTerm: '',
    selectedYear: '',
  };

  exportSites = () => {
    const tableData = this.selectTable.getResolvedState().sortedData;
    const filteredData = tableData.map(({ _original }) => _original);

    const columnData = [
      { header: 'Site Name', key: 'name' },
      { header: 'Application Type', key: 'type' },
      { header: 'Application Year', key: 'applicationYear' },
      { header: 'Address 1', key: 'address1' },
      { header: 'Address 2', key: 'address2' },
      { header: 'City', key: 'city' },
      { header: 'County', key: 'county' },
      { header: 'State', key: 'state' },
      { header: 'Zip', key: 'zip' },
      { header: 'Cross Street', key: 'crossStreet' },
      { header: 'Closest School', key: 'closestSchool' },
      { header: 'Date Submitted', key: 'submittedAtFormatted' },
      { header: 'Program Start Date', key: 'startDateFormatted' },
      { header: 'Program End Date', key: 'endDateFormatted' },
      { header: 'Program Status', key: 'statusKidsCafe' },
      { header: 'Application Status', key: 'applicationStatus' },
      { header: 'Total Expected Participants', key: 'numExpectedParticipants' },
      { header: 'Meal Type Breakfast', key: 'interestBreakfastNormalized' },
      { header: 'Meal Type Brunch', key: 'interestBrunchNormalized' },
      { header: 'Meal Type Lunch', key: 'interestLunchNormalized' },
      { header: 'Meal Type BOGO', key: 'interestBogoLunchNormalized' },
      { header: 'Meal Type Snack', key: 'interestSnackNormalized' },
      { header: 'Meal Type Supper', key: 'interestSupperNormalized' },
      { header: 'Expected Participants Breakfast', key: 'numExpectedParticipantsBreakfast' },
      { header: 'Expected Participants Brunch', key: 'numExpectedParticipantsBrunch' },
      { header: 'Expected Participants Lunch', key: 'numExpectedParticipantsLunch' },
      { header: 'Expected Participants BogoLunch', key: 'numExpectedParticipantsBogoLunch' },
      { header: 'Expected Participants Snack', key: 'numExpectedParticipantsSnack' },
      { header: 'Expected Participants Supper', key: 'numExpectedParticipantsSupper' },
      { header: 'Breakfast Start Time', key: 'breakfastStartTime' },
      { header: 'Breakfast End Time', key: 'breakfastEndTime' },
      { header: 'Brunch Start Time', key: 'brunchStartTime' },
      { header: 'Brunch End Time', key: 'brunchEndTime' },
      { header: 'Lunch Start Time', key: 'lunchStartTime' },
      { header: 'Lunch End Time', key: 'lunchEndTime' },
      { header: 'BOGO Lunch Start Time', key: 'bogoLunchStartTime' },
      { header: 'BOGO Lunch End Time', key: 'bogoLunchEndTime' },
      { header: 'Snack Start Time', key: 'snackStartTime' },
      { header: 'Snack End Time', key: 'snackEndTime' },
      { header: 'Supper Start Time', key: 'supperStartTime' },
      { header: 'Supper End Time', key: 'supperEndTime' },
      { header: 'Closure Dates', key: 'closureDates' },
      { header: 'Certified Verification', key: 'certififedVerification' },
      { header: 'Primary Point of Contact Name', key: 'primaryPointOfContactName' },
      { header: 'Primary Point of Contact Email', key: 'primaryPointOfContactEmail' },
      { header: 'Primary Point of Contact Phone', key: 'primaryPointOfContactPhone' },
      { header: 'Secondary Point of Contact Name', key: 'secondaryPointOfContactName' },
      { header: 'Secondary Point of Contact Email', key: 'secondaryPointOfContactEmail' },
      { header: 'Secondary Point of Contact Phone', key: 'secondaryPointOfContactPhone' },
    ];

    const rowData = [];

    filteredData.forEach((site) => {
      const primaryPointOfContact = site.siteMembers.find(
        ({ type }) => type === 'PRIMARY_POINT_CONTACT',
      );
      const secondaryPointOfContact = site.siteMembers.find(
        ({ type }) => type === 'SECONDARY_POINT_CONTACT',
      );

      const siteData = {
        ...site,
        primaryPointOfContactName: get(primaryPointOfContact, 'fullName', 'N/A'),
        primaryPointOfContactEmail: get(primaryPointOfContact, 'email', 'N/A'),
        primaryPointOfContactPhone: get(primaryPointOfContact, 'phone', 'N/A'),
        secondaryPointOfContactName: get(secondaryPointOfContact, 'fullName', 'N/A'),
        secondaryPointOfContactEmail: get(secondaryPointOfContact, 'email', 'N/A'),
        secondaryPointOfContactPhone: get(secondaryPointOfContact, 'phone', 'N/A'),
      };

      const { selectedYear } = this.state;

      const selectedType = selectedYear.split(' ')[0];
      const selectedYearOnly = selectedYear.split(' ')[1];

      const siteApplicationsKidsCafe = site.applicationsKidsCafe.filter(
        ({ type, applicationYear }) => {
          if (selectedYear) {
            return type === selectedType && applicationYear === selectedYearOnly;
          }

          return true;
        },
      );

      if (siteApplicationsKidsCafe.length) {
        siteApplicationsKidsCafe.forEach((application) => {
          const applicationStatus = application.status;
          const participatedLastYear = application.new ? 'No' : 'Yes';
          const daysForServiceFormatted = application.daysForService.join(', ');
          const submittedAtFormatted = application.submittedAt
            ? moment.utc(application.submittedAt).format('M-D-YY')
            : '';
          const startDateFormatted = application.startDate
            ? moment.utc(application.startDate).format('M-D-YY')
            : '';
          const endDateFormatted = application.endDate
            ? moment.utc(application.endDate).format('M-D-YY')
            : '';
          const interestBreakfastNormalized = application.interestBreakfast ? 'Yes' : 'No';
          const interestBrunchNormalized = application.interestBrunch ? 'Yes' : 'No';
          const interestLunchNormalized = application.interestLunch ? 'Yes' : 'No';
          const interestBogoLunchNormalized = application.interestBogoLunch ? 'Yes' : 'No';
          const interestSnackNormalized = application.interestSnack ? 'Yes' : 'No';
          const interestSupperNormalized = application.interestSupper ? 'Yes' : 'No';
          const breakfastStartTime = application.breakfastStartTime
            ? application.breakfastStartTime
            : '';
          const breakfastEndTime = application.breakfastEndTime ? application.breakfastEndTime : '';
          const brunchStartTime = application.brunchStartTime ? application.brunchStartTime : '';
          const brunchEndTime = application.brunchEndTime ? application.brunchEndTime : '';
          const lunchStartTime = application.lunchStartTime ? application.lunchStartTime : '';
          const lunchEndTime = application.lunchEndTime ? application.lunchEndTime : '';
          const bogoLunchStartTime = application.bogoLunchStartTime
            ? application.bogoLunchStartTime
            : '';
          const bogoLunchEndTime = application.bogoLunchEndTime ? application.bogoLunchEndTime : '';
          const snackStartTime = application.snackStartTime ? application.snackStartTime : '';
          const snackEndTime = application.snackEndTime ? application.snackEndTime : '';
          const supperStartTime = application.supperStartTime ? application.supperStartTime : '';
          const supperEndTime = application.supperEndTime ? application.supperEndTime : '';

          rowData.push({
            submittedAtFormatted,
            participatedLastYear,
            daysForServiceFormatted,
            applicationStatus,
            startDateFormatted,
            endDateFormatted,
            interestBreakfastNormalized,
            interestBrunchNormalized,
            interestLunchNormalized,
            interestBogoLunchNormalized,
            interestSnackNormalized,
            interestSupperNormalized,
            breakfastStartTime,
            breakfastEndTime,
            brunchStartTime,
            brunchEndTime,
            lunchStartTime,
            lunchEndTime,
            bogoLunchStartTime,
            bogoLunchEndTime,
            snackStartTime,
            snackEndTime,
            supperStartTime,
            supperEndTime,
            ...siteData,
            ...omit(application, 'status'),
          });
        });
      } else {
        rowData.push({
          ...siteData,
        });
      }
    });

    exportExcel('sites', columnData, rowData);
  };

  selectFilter = (props, sites, options = null) => {
    const { filter: filterObj, onChange, column } = props;

    const uniqOptions = uniq(sites.map(site => get(site, `[${column.id}]`)));

    return (
      <select
        className="filter-select w-select"
        onChange={event => onChange(event.target.value)}
        value={filterObj ? filterObj.value : ''}
      >
        <option value="">{column.Header}</option>
        {uniqOptions.map(value => (
          <option key={`${value}+${column.id}`} value={value}>
            {options && value !== 'N/A' ? getLabel(options, value) : value}
          </option>
        ))}
      </select>
    );
  };

  searchResults(sites) {
    const { searchTerm, selectedYear } = this.state;

    if (selectedYear !== '') {
      const selectedType = selectedYear.split(' ')[0];
      const selectedYearOnly = selectedYear.split(' ')[1];
      const applicationSites = sites.filter(site => site.applicationsKidsCafe.length);
      const filteredSites = applicationSites.filter((site) =>
        site.applicationsKidsCafe.some(
          (application) =>
            application.type === selectedType && application.applicationYear === selectedYearOnly,
        ),
      );
      return filteredSites;
    }

    if (searchTerm !== '') {
      return filter(
        sites,
        ({ name, address1, address2, city, state, zip, county, siteMembers, statusKidsCafe }) => {
          const lowerCase = (text) => text.toLowerCase();
          const regexFunc = (fieldValueSearch) => new RegExp(searchTerm).test(fieldValueSearch);
          const primaryPointOfContact = siteMembers.find(
            ({ type }) => type === 'PRIMARY_POINT_CONTACT',
          );

          return (
            regexFunc(lowerCase(name)) ||
            regexFunc(lowerCase(county)) ||
            regexFunc(lowerCase(address1)) ||
            (address2 && regexFunc(lowerCase(address2))) ||
            regexFunc(lowerCase(city)) ||
            regexFunc(lowerCase(state)) ||
            regexFunc(zip) ||
            (primaryPointOfContact && regexFunc(lowerCase(primaryPointOfContact.fullName))) ||
            (primaryPointOfContact && regexFunc(lowerCase(primaryPointOfContact.email))) ||
            regexFunc(lowerCase(statusKidsCafe))
          );
        },
      );
    }
    return sites;
  }

  render() {
    const { enums, dispatch } = this.props;

    const { selectedYear } = this.state;

    const { programStatuses } = enums;

    const yearAndTypeOptions = (sites) => {
      const applicationSites = sites.filter((site) => site.applicationsKidsCafe.length);
      const years = [];
      applicationSites.map((site) =>
        site.applicationsKidsCafe.map((application) => years.push(application.applicationYear)),
      );
      const uniqYears = uniq(years);
      const typeValues = [];
      uniqYears.map((year) =>
        typeValues.push(
          {
            value: `SUMMER ${year}`,
            label: `Summer ${year}`,
          },
          {
            value: `SCHOOL ${year}`,
            label: `School Year ${year}`,
          },
        ),
      );
      return typeValues;
    };

    return (
      <Query query={sitesQuery} fetchPolicy="cache-and-network">
        {({ loading, data, error }) => {
          if (error) return <BlankState title="Error" subtitle="There was an error loading" />;
          if (!data.sites && loading) return <Loading />;

          const { sites } = data;
          const filteredSites = this.searchResults(sites);
          const findPrimaryContact = (row) =>
            row.siteMembers && row.siteMembers.find(({ type }) => type === 'PRIMARY_POINT_CONTACT');
          const yearTypeOptions = yearAndTypeOptions(sites);

          const columns = [
            {
              Header: 'Site',
              accessor: 'name',
              filterable: false,
            },
            {
              id: 'address',
              Header: 'Address',
              accessor: (row) =>
                `${row.address1}, ${row.address2 !== null ? `${row.address2},` : ''} ${row.city}, ${
                  row.state
                }, ${row.zip}`,
            },
            {
              Header: 'County',
              accessor: 'county',
            },
            {
              Header: 'Program Status',
              accessor: 'statusKidsCafe',
              Cell: (row) => getLabel(programStatuses.options, row.value),
              Filter: (props) => this.selectFilter(props, sites, programStatuses.options),
            },
            {
              id: 'fullName',
              Header: 'Contact Name',
              accessor: (row) => get(findPrimaryContact(row), 'fullName'),
              filterable: false,
            },
            {
              id: 'email',
              Header: 'Contact Email',
              accessor: (row) => get(findPrimaryContact(row), 'email'),
              filterable: false,
            },
            {
              id: 'phone',
              Header: 'Contact Phone',
              accessor: (row) => get(findPrimaryContact(row), 'phone'),
              filterable: false,
            },
            {
              id: 'new',
              Header: 'Returning',
              accessor: (d) => {
                const activeApplications = d.applicationsKidsCafe.filter(
                  ({ status }) => status === 'APPROVED',
                );
                const mostRecent = maxBy(activeApplications, (o) => o.createdAt);
                if (mostRecent) {
                  return mostRecent.new ? 'No' : 'Yes';
                }
                return 'N/A';
              },
              Filter: (props) => {
                const { filter: filterObj, onChange, column } = props;
                return (
                  <select
                    className="filter-select w-select"
                    onChange={(event) => onChange(event.target.value)}
                    value={filterObj ? filterObj.value : 'all'}
                  >
                    <option value="">{column.Header}</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                );
              },
            },
            {
              id: 'archived',
              Header: 'Archived',
              accessor: (row) => (row.deletedAt ? 'Yes' : 'No'),
              Filter: (props) => {
                const { onChange, column } = props;
                return (
                  <select
                    className="filter-select w-select"
                    onChange={(event) => onChange(event.target.value)}
                  >
                    <option value="No">{column.Header}</option>
                    <option value="">Show All</option>
                  </select>
                );
              },
            },
          ];

          return (
            <Fragment>
              <h2>Sites</h2>
              <Fragment>
                <div className="date-export admin-schools backpack-buddy">
                  <div className="date-range">
                    <div className="date-from">
                      <span className="link-block-8 w-inline-block admin-school-select-wrapper">
                        <select
                          className="text-block-7 admin-school-select"
                          value={selectedYear}
                          onChange={(e) => {
                            this.setState({ selectedYear: e.target.value });
                            return this.searchResults(filteredSites);
                          }}
                        >
                          <option value="">Select Type:</option>
                          {yearTypeOptions.map(({ value, label }) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ))}
                        </select>
                        <img
                          src={ChevronDropIcon}
                          width={18}
                          alt="school-year"
                          className="image-8"
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="date-export admin-schools backpack-buddy">
                  <div onClick={this.exportSites} className="export-btn w-button admin-schools">
                    Export
                  </div>
                </div>
                <input
                  type="text"
                  className="search-bar w-input"
                  onChange={(evt) =>
                    this.setState({ searchTerm: evt.target.value.trim().toLowerCase() })
                  }
                  placeholder="Search"
                />
                <ReactTable
                  className="schools-table kids-cafe"
                  ref={(r) => {
                    this.selectTable = r;
                  }}
                  data={filteredSites}
                  columns={columns}
                  loadingText=""
                  NoDataComponent={() => <BlankState title="No Results" />}
                  minRows={0}
                  filterable
                  defaultPageSize={PAGE_SIZE}
                  PaginationComponent={Pagination}
                  defaultFiltered={[
                    {
                      id: 'archived',
                      value: 'No',
                    },
                  ]}
                  defaultSorted={[
                    {
                      id: 'name',
                      desc: false,
                    },
                  ]}
                  getTrGroupProps={(state, rowInfo) => ({
                    className: 'table-row school-row w-clearfix',
                    style: { cursor: 'pointer' },
                    onClick: () => dispatch(push(`/kids-cafe/sites/${rowInfo.original.siteId}`)),
                  })}
                  getTdProps={() => ({
                    className: 'table-col schools-cells',
                  })}
                  getTheadTrProps={() => ({
                    className: 'table-row table-header w-clearfix',
                  })}
                  getTheadFilterTrProps={() => ({
                    className: 'filters',
                  })}
                  column={{
                    ...ReactTableDefaults.column,
                    headerClassName: 'table-col schools-header w-inline-block pointer noselect',
                    minWidth: 85,
                    width: '10%',
                    Cell: (row) => row.value || 'N/A',
                    Filter: (props) => this.selectFilter(props, sites),
                  }}
                />
              </Fragment>
            </Fragment>
          );
        }}
      </Query>
    );
  }
}

SiteTable.propTypes = {
  enums: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default SiteTable;
