import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

const KidsCafeProgramAgreement = ({
  input,
  meta: { touched, error },
}) => {
  const handleContainerOnBottom = useCallback(() => {
    if (!input.value) {
      input.onChange(true);
    }
  }, [input]);

  const containerRef = useBottomScrollListener(handleContainerOnBottom, 50);

  return (
    <Fragment>
      <h4 className="purple-headline boxed-in">The Agreement</h4>
      <p>
        This document is an agreement made between the Houston Food Bank (hereinafter referred
        to as “HFB”) located at 535 Portwall St. Houston, TX 77029 and your site (hereinafter
        referred to as “Kids Cafe Partner Site”).
      </p>
      <div ref={containerRef} className="agreement-div scroll">
        <h5 className="black-headline">The Program Partner/Kids Cafe site agrees to:</h5>
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item">
            <p>
              <span className="line-item">1.</span>
              Serve healthy, nutritious meals to children, provided by the Houston Food Bank free of charge at all times.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">2.</span>
             Provide meals or snacks a minimum of 15 separate times a year.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">3.</span>
              Ensure the site complies with all applicable federal and local statutes, ordinances and regulations.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">4.</span>
              Conduct National Background Check on staff and volunteers with direct repetitive contact with children.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">5.</span>
              Have an adult to child ratio of a minimum of 2 adults for the first 25 children at all times that the Kids Cafe program is operational.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">6.</span>
              At least one representative must receive food safety training developed by Feeding America and the National Restaurant Association, or an equivalent training provided by the Houston Food Bank.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">7.</span>
              Participate in nutrition education with students at a minimum of once annually during a meal service using approved HFB Nutrition Education course options.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">8.</span>
              Be available for an on-site monitoring visit at least three times a year from the Houston Food Bank.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">9.</span>
              Store Kids Cafe Program food in a secure, sanitary and temperature-controlled place away from cleaning materials and toxic chemicals.  All food must be stored six inches off the floor, away from the wall and six inches below the ceiling.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">10.</span>
              Distribute the Kids Cafe meals or snacks to program participants in accordance with the pre-determined schedule.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">11.</span>
              Maintain the Ingredient Folder provided by the Houston Food Bank on site at all times.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">12.</span>
              Must track meal service by using the electronic record keeping website Link 2 Feed.
              <br />
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">13.</span>
              Must maintain documentation of accurate records (attendance, submitted reports, meal counts, and etc.) on site and be able to provide necessary information as requested.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">14.</span>
              Will be accessible for receiving deliveries on service day up to four (4) hours before service time.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">15.</span>
              Provide Houston Food Bank with a seven (7)-business day written notice of any changes to their Kids Cafe program personnel, days/hours of operation, number of children being served, and/or necessary paperwork to ensure TDA compliance.
              <br />
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">16.</span>
              Child Care Centers: Facilitate, collect, and maintain all required MBIE and enrollment forms from enrolled children to ensure proper documentation of reimbursed classification.  Ensure all students being served have proper documentations for program. (For-Profit Centers must maintain a minimum of 30% Free and Reduced classified student status at all times.)
            </p>
          </li>
        </ul>
      </div>
      {touched && error
        && (
          <span
            style={{
              color: 'red',
            }}
          >
            You must read the above Agreement
          </span>
        )
      }
    </Fragment>
  );
};

KidsCafeProgramAgreement.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default KidsCafeProgramAgreement;
