import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import LaunchesImg from '../../images/launch.svg';
import getLabel from '../../helpers/getLabel';
import isSite from '../../helpers/isSite';
import {
  userApplicationsBackpackBuddyQuery,
  userApplicationsSchoolMarketQuery,
  userApplicationsTeachersAidQuery,
  userApplicationsKidsCafeQuery,
} from '../../apollo';
import BlankState from '../BlankState';
import Loading from '../Loading';

const ApplicationsList = (props) => {
  const { enums } = props;
  const { program: { name, programUrl } } = props;

  let query;
  let applicationId;
  let value;
  switch (name) {
    case 'Backpack Buddy':
      query = userApplicationsBackpackBuddyQuery;
      applicationId = 'applicationBackpackBuddyId';
      value = 'userApplicationsBackpackBuddy';
      break;
    case 'School Market':
      query = userApplicationsSchoolMarketQuery;
      applicationId = 'applicationSchoolMarketId';
      value = 'userApplicationsSchoolMarket';
      break;
    case 'Teachers Aid':
      query = userApplicationsTeachersAidQuery;
      applicationId = 'applicationTeachersAidId';
      value = 'userApplicationsTeachersAid';
      break;
    case 'Kids Cafe':
      query = userApplicationsKidsCafeQuery;
      applicationId = 'applicationKidsCafeId';
      value = 'userApplicationsKidsCafe';
      break;
    default:
      break;
  }

  return (
    <Query
      query={query}
    >
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <BlankState title="Error" subtitle="There was an error loading" />;
        if (!data[value].length) return <BlankState title="No Results" />;

        return (
          <div>
            <div className="applications-table-row w-clearfix">
              <span className="app-table-col year w-inline-block">
                <div className="table-data year">{isSite(name) ? 'Site' : 'School'}</div>
              </span>
              <span className="app-table-col year w-inline-block">
                <div className="table-data year">Application Year</div>
              </span>
              <span className="app-table-col year w-inline-block">
                <div className="table-data status">Status</div>
              </span>
            </div>

            {data[value].map(application => (
              <div className="table-row applications w-clearfix" key={application[applicationId]}>
                <div className="app-table-col year">
                  {
                    isSite(name)
                      ? <div>{application.site && application.site.name}</div>
                      : <div>{application.school && application.school.name}</div>
                  }
                </div>
                <div className="app-table-col year">
                  {
                    isSite(name)
                      ? <div>{application.type === 'SUMMER' ? `Summer ${application.applicationYear}` : `School Year ${application.applicationYear}`}</div>
                      : <div>{application.termFormattedShort}</div>
                  }
                </div>
                <div className="app-table-col status">
                  {
                    isSite(name)
                      ? <div>{getLabel(enums.applicationStatuses.options, application.status)}</div>
                      : <div>{getLabel(enums.applicationSchoolStatuses.options, application.status)}</div>
                  }
                </div>
                <div className="app-table-col actions">
                  <Link
                    to={`${programUrl}/application/step-1/${application[applicationId]}`}
                    className="crud-action-icon-wrapper-2 w-inline-block"
                    title="View Application"
                  >
                    <img src={LaunchesImg} width={20} alt="View Application" />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        );
      }}
    </Query>
  );
};

ApplicationsList.propTypes = {
  enums: PropTypes.object.isRequired,
  program: PropTypes.object.isRequired,
};

export default ApplicationsList;
