import gql from 'graphql-tag';

export default gql`
  query adminSchoolTeachersAid ($schoolId: ID!) {
    adminSchoolTeachersAid(schoolId: $schoolId) {
      id
      schoolId
      name
      teachersAidSchoolNumber
      address1
      address2
      city
      state
      zip
      county
      districtId
      type
      gradesServed
      lastEdited
      lastEditedTeamMembers
      firstYearAppliedTeachersAid
      statusTeachersAid
      statusTeachersAidUpdatedAt
      deletedAt
      applicationsTeachersAid {
        id
        applicationTeachersAidId
        new
        createdAt
        submittedAt
        submittedBy
        status
        termFormattedShort
        numFreeLunch
        numReducedLunch
        numTotalEnrollment
      }
      teamMembersTeachersAid {
        id
        teamMemberId
        firstName
        lastName
        email
        phone
        type
        title
      }
    }
  }
`;
