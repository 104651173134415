import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import AdminSchoolProgramSettingsPage from '../pages/AdminSchoolProgramSettingsPage';
import AdminSchoolsPage from '../pages/AdminSchoolsPage';
import AdminDistrictsPage from '../pages/AdminSchoolProgramDistrictsPage';
import AdminUsersPage from '../pages/AdminSchoolProgramUsersPage';
import ApplicationTeachersAid from '../components/ApplicationTeachersAid';
import SignaturePage from '../pages/SignaturePage';

const TeachersAidAdminRoutes = ({ match }) => (
  <Switch>
    <AuthRoute path={`${match.path}/schools`} component={AdminSchoolsPage} />
    <AuthRoute path={`${match.path}/districts`} component={AdminDistrictsPage} />
    <AuthRoute path={`${match.path}/users`} component={AdminUsersPage} />
    <AuthRoute path={`${match.path}/program-settings`} component={AdminSchoolProgramSettingsPage} />
    <AuthRoute path={`${match.path}/application/:step/:id`} component={ApplicationTeachersAid} />
    <AuthRoute exact path={`${match.path}/principal-signature/:id`} component={SignaturePage} />
    <Route component={() => <Redirect to={`${match.path}/schools`} />} />
  </Switch>
);

TeachersAidAdminRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default TeachersAidAdminRoutes;
